import { createStore, combineReducers } from 'redux';
import blockReducer from './reducers/block_reducer';
import optionReducer from './reducers/option_reducer';
import chatbotReducer from './reducers/chatbot_reducer';
import userReducer from './reducers/user_reducer';

const rootReducer = combineReducers({
    blocks: blockReducer,
    options: optionReducer,
    chatbot: chatbotReducer,
    user: userReducer
});

const store = createStore(rootReducer);

export default store;