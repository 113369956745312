const initialState = {
    advanced_option: {
        animations: false,
        sketches: false,
        powerpoints: false,
        presenter: false,
        nobgmusic: false,
        highSpeech: false,
        readability: false,
        duration: [1, 300],
        year: [2000, new Date().getFullYear()],
        matching: [0, 100],
    },
    search_option: {
        exam: '',
        topic: '',
        section: '',
        search_term: ''
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'change_search_option':
            return {
                ...state,
                search_option: action.payload
            };
        case 'change_advanced_option':
            return {
                ...state,
                advanced_option: action.payload
            }
        default:
            return state;
    }
};

export default reducer;