import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from 'helpers/api';
import validator from 'validator';
import passwordValidator from 'password-validator';
import './_signup.scss';

import googleImg from 'assets/google.png';
import { ReactComponent as LeafSVG } from 'assets/leaf.svg';

export default function Signup() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [isValidated, setIsValidated] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    function formValidator() {
        if( isValidated.name === true && isValidated.email === true && isValidated.password.length === 0 && isValidated.confirmed === true)
            return true;
        else
            return false;
    }

    function signupUser() {
        // console.log(isValidated, formValidator())
        if(!formValidator()) return;
        setLoading(true)
        const data = {
            fullname: name,
            email: email,
            password: password
        }
        try {
            register(data).then(res => {
                setLoading(false)
                navigate('/login', { replace: true });    
            }).catch(err => {
                setLoading(false)
                setErrorMessage('User register error!')
            })
        } catch {
            console.log('Network Error!');
            setLoading(false)
        }
    }
    return (
        <div className="signup_page">
            <div className='signup_container'>
                <div className='signup_title'>
                    <h2 className='welcome_title'>Account Signup</h2>
                    <span>Become a member and enjoy exclusive promotions.</span>
                </div>
                <div className='signup_form'>
                    <div className='d-flex flex-column'>
                        <input
                            type='text'
                            name='name'
                            placeholder='Name'
                            value={name}
                            required
                            onChange={ e => {
                                setName(e.target.value)
                                if(e.target.value === '')
                                    setIsValidated({...isValidated, name: false});
                                else
                                    setIsValidated({...isValidated, name: true});
                            }}
                        />
                        {
                            isValidated.name === false &&
                            <span className='text-danger'>Please input name</span>
                        }
                    </div>
                    <div className='d-flex flex-column'>
                        <input
                            type='email'
                            name='email'
                            placeholder='Email'
                            value={email}
                            required
                            onChange={ e => {
                                setEmail(e.target.value)
                                if(e.target.value === '' || !validator.isEmail(e.target.value))
                                    setIsValidated({...isValidated, email: false});
                                else
                                    setIsValidated({...isValidated, email: true});
                            }}
                        />
                        {
                            isValidated.email === false &&
                            <span className='text-danger'>Please input valid email</span>
                        }
                        
                    </div>
                    <div className='d-flex flex-column'>
                        <input
                            type='password'
                            name='password'
                            placeholder='Password'
                            value={password}
                            required
                            onChange={ e => {
                                setPassword(e.target.value)
                                const schema = new passwordValidator();
                                schema
                                    .is().min(6)                                    // Minimum length 8
                                    .is().max(100)                                  // Maximum length 100
                                    .has().uppercase()                              // Must have uppercase letters
                                    .has().lowercase()                              // Must have lowercase letters
                                    .has().digits(2)                                // Must have at least 2 digits
                                if(e.target.value !== confirm)
                                    setIsValidated({...isValidated, confirmed: false, password: schema.validate(e.target.value, { details: true })});
                                else
                                    setIsValidated({...isValidated, confirmed: true, password: schema.validate(e.target.value, { details: true })});
                            }}
                        />
                        {
                            isValidated.password && isValidated.password.length !== 0 &&
                            <div className='d-flex gap-2 text-danger mt-2 '>
                                {
                                    isValidated.password.map( (item, idx) => 
                                        <span key={idx} className='badge text-bg-danger '>
                                            <span className='text-uppercase small'>{item.validation}</span>
                                            {
                                                item.arguments &&
                                                <span className='small'> - {item.arguments}</span>
                                            }
                                        </span> 
                                    )
                                }
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column'>
                        <input
                            type='password'
                            name='confirm'
                            placeholder='Confirm'
                            value={confirm}
                            onChange={ e => {
                                setConfirm(e.target.value)
                                if(e.target.value !== password)
                                    setIsValidated({...isValidated, confirmed: false});
                                else
                                    setIsValidated({...isValidated, confirmed: true});
                            }}
                        />
                        {
                            isValidated.confirmed === false &&
                            <div className='text-danger small mt-2'>Should be matched with password</div>
                        }
                    </div>
                    
                    <button onClick={signupUser}>
                        {
                            loading ?  "Loading..." : "Sign Up"
                        }
                    </button>
                    <span className='text-danger text-center'>{errorMessage}</span>
                    <span className='text-white text-center'>
                        I already have an account. <a href='/login' className='text-decoration-none'>Login</a>
                    </span>
                </div>
                
                <div className='sso_form'>
                    <div className='seperator'>
                        <div className='line'></div>
                        <div>
                            <span>Signup</span> with Others
                        </div>
                        <div className='line'></div>
                    </div>
                    <button onClick={() => {
                        window.open("https://edcompass.io/google_login", "_self")
                    }}>
                        <img src={googleImg} alt='' />
                        Signup with Google
                    </button>
                </div>
                <LeafSVG style={{ position: 'absolute', top: '100px', right: '60px', zIndex: 0 }} />
                <LeafSVG style={{ position: 'absolute', bottom: '-120px', left: '48px', zIndex: 0, transform: 'rotateZ(320deg)' }}/>
            </div>
        </div>
    );
}