import React from 'react';
import './_loading.scss';

export default function Loading() {

  return (
    <div className='loading_wrapper'>
        <div className='loader'></div>
    </div>
  );
}
