const initialState = {
    user: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setUserInfo':
            return({
                ...state,
                user: action.payload
            })
        case 'logout':
            sessionStorage.removeItem('access_token')
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
};

export default reducer;