import { useEffect } from 'react';
import ReactGA from 'react-ga4'

import env from 'config'
import './_style.scss';

import logo_head from 'assets/images/logo_head.png';

export default function NotFound () {

    useEffect(() => {
        ReactGA.initialize(env.GA_TrackingId);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Visit Wrong URL" })

    }, [])
    return (
        <>
            <div className="container page-not-found">
                <div className='note_404'>
                    <span>4</span>
                    <img src={logo_head} alt='0' />
                    <span>4</span>
                </div>

                <div className='btn_home'>
                    <a href='/'>Back to Home</a>
                </div>
            </div>


        </>
        
    )
}