const initialState = {
    messages: [],
    session_id: null,
    result_asking: false,
    link_asking: false,
    asking_info: null,
    asking_link: '',
    isThinking: false,
    isOpenChatbot: false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'set_session_id':
            return {
                ...state,
                session_id: action.payload
            }
        case 'update_messages':
            return {
                ...state,
                messages: action.payload
            };
        case 'result_asking':
            return {
                ...state,
                result_asking: action.payload.result_asking,
                asking_info: action.payload.info
            }
        case 'link_asking':
            return {
                ...state,
                link_asking: action.payload.link_asking,
                asking_info: null
            }
        case 'update_asking_link':
            return {
                ...state,
                asking_link: action.payload
            }
        case 'thinking':
            return {
                ...state,
                isThinking: action.payload
            }
        case 'OpenChatbot':
            return {
                ...state,
                isOpenChatbot: action.payload
            }
        default:
            return state;
    }
};

export default reducer;