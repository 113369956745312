const initialState = {
    blocks: [],
    isPlaying: false,
    playing_vid: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'set_blocks':
            return {
                ...state,
                blocks: action.payload
            }
        case 'play_video':
            console.log('playing')
            return {
                ...state,
                isPlaying: true,
                playing_vid: action.payload
            };
        case 'pause_video':
            console.log('paused')
            return {
                ...state,
                isPlaying: false,
                playing_vid: ''
            };
        case 'end_video':
            return {
                ...state,
                isPlaying: false,
                playing_vid: ''
            };
        default:
            return state;
    }
};

export default reducer;