import './_style.scss';

export default function CookiesModal (props) {

    return (
        <div className='cookies'>
            <div className='cookies_modal'>
                <div className='cookie_title'>
                    Cookie consent
                </div>
                <div className='cookie_content'>
                    <p>
                        This website uses Cookies to offer you a better browsing experience, analyze site traffic, and personalize content. Read our <a target='_blank' className='cookie_link' href="https://privacy.edcompass.io">privacy policy</a> here. <br />
                        This website also uses YouTube API services. By using our services, you agree to YouTube's Terms of Services. 
                    </p>
                    <p>
                        <a className='cookie_link' href="https://www.youtube.com/t/terms">YouTube Terms of Services</a>, <a className='cookie_link' href="https://policies.google.com/privacy?hl=en">Privacy Policy</a>, Google <a className='cookie_link' href="https://policies.google.com/privacy?hl=en">privacy policy</a>
                    </p>
                </div>
                <div className='cookie_footer'>
                    <div className='btn_cookie_accept' onClick={() => {
                        props.cookieAccept()
                    }}>
                        Accept & continue
                    </div>
                </div>
            </div>
        </div>
    )
}
