import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login, setAuth } from 'helpers/api';
import './_login.scss';

import googleImg from 'assets/google.png';
import { ReactComponent as LeafSVG } from 'assets/leaf.svg';


export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const access_token = params.get('access_token')
        if(access_token) {
            setAuth(access_token)
            navigate('/');
        }
        
    }, [location])

    function loginUser() {
        if(loading) return
        const data = {
            email: email,
            password: password
        }
        setLoading(true)
        try {
            login(data).then(res => {
                setLoading(false)
                navigate('/user/media');
            }).catch(err => {
                setLoading(false)
                setErrorMessage('Invalid email or password')
            })
        } catch {
            console.log('Network Error!');
            setLoading(false)
        }
    }

    return (
        <div className="login_page"
            onKeyDown={(e) => {
                if(e.key === 'Enter') {
                    loginUser();
                }
            }}
        >
            <div className='login_container'>
                <div className='login_title'>
                    <h2 className='welcome_title'>Account Login</h2>
                    <span>We are glad to see you back with us</span>
                </div>
                <div className='login_form'>
                    <input
                        type='email'
                        name='email'
                        placeholder='Email'
                        value={email}
                        onChange={ e => {
                            setErrorMessage('')
                            setEmail(e.target.value)
                        }}
                    />
                    <input
                        type='password'
                        name='password'
                        placeholder='Password'
                        value={password}
                        onChange={ e => {
                            setErrorMessage('')
                            setPassword(e.target.value)
                        }}
                    />
                    <button onClick={loginUser}>
                        {
                            loading ?  "Loading..." : "Sign In"
                        }
                    </button>
                    <span className='text-danger text-center'>{errorMessage}</span>
                    <span>
                        Don't you have an account? <a href='/signup'>Register</a>
                    </span>
                </div>
                
                <div className='sso_form'>
                    <div className='seperator'>
                        <div className='line'></div>
                        <div>
                            <span>Login</span> with Others
                        </div>
                        <div className='line'></div>
                    </div>
                    <button onClick={() => {
                        window.open("https://edcompass.io/google_login", "_self")
                    }}>
                        <img src={googleImg} alt='' />
                        Login with Google
                    </button>
                </div>
                <LeafSVG style={{ position: 'absolute', top: '100px', right: '60px', zIndex: 0 }} />
                <LeafSVG style={{ position: 'absolute', bottom: '-120px', left: '48px', zIndex: 0, transform: 'rotateZ(320deg)' }}/>
            </div>
        </div>
    );
}
  